import React, { forwardRef } from 'react';
import { useLanguage } from './LanguageProvider';

const QuestionSubmitButton = ({ loading, ...props }, ref) => {
  const language = useLanguage();
  const { caption = language === 'nl' ? 'Volgende' : 'Next' } = props; // XXX use Translation lib, fallback = Next

  return (
    <button
      type="submit"
      id="next"
      className={`button is-primary brand-primary is-medium is-fullwidth${loading ? ' is-loading' : ''}`}
      ref={ref}
      {...props}
    >
      {caption}
    </button>
  );
};

export default forwardRef(QuestionSubmitButton);
