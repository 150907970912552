import React, { useEffect, useState } from 'react';

import validate from '../validators/validateTextMultiple';
import QuestionText from './QuestionText';

const QuestionFormTextMultiple = ({
  question,
  savedAnswer,
  variables,
  onChange,
  onSubmit,
  onSubmitAndBack,
  onFocusSubmit,
  children,
}) => {
  const { options } = question;
  const initialStateMap = savedAnswer || {};
  const [stateMap, setStateMap] = useState(initialStateMap);
  useEffect(onFocusSubmit, []);

  const handleChange = (key, value) => {
    setStateMap(s => ({ ...s, [key]: value }));
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const values = options.reduce((total, current) => {
      total[current.key] = stateMap[current.key] === undefined ? '' : stateMap[current.key];
      return total;
    }, {});
    if (event.nativeEvent.submitter.id === 'next') {
      onSubmit(values, validate);
    } else {
      onSubmitAndBack(values);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {options.map(option => (
        <QuestionText
          key={option.key}
          option={option}
          variables={variables}
          answer={stateMap[option.key]}
          onChange={event => handleChange(option.key, event.target.value)}
        />
      ))}
      {children}
    </form>
  );
};

export default QuestionFormTextMultiple;
