export default (question, answer, language = 'nl') => {
  const { options, minValue, maxValue, allowDecimals, totalValue } = question;
  if (answer.length < options.length) {
    throw new Error(language === 'nl' ? 'Deze velden mogen niet worden leeggelaten.' : 'These fields cannot be empty.');
  }

  let total = 0;
  // eslint-disable-next-line no-unused-vars
  for (const [key, chosenOption] of Object.entries(answer)) {
    let number = chosenOption;
    if (typeof chosenOption !== 'number') {
      number = allowDecimals ? parseFloat(number.replace(',', '.')) : parseInt(number, 10);
    }
    if (Number.isNaN(number)) {
      throw new Error(language === 'nl' ? 'Vul een geldig getal in.' : 'Please enter a valid number.');
    } else if (minValue !== undefined && minValue !== null && number < minValue) {
      throw new Error(
        language === 'nl' ? `Getal dient minimaal ${minValue} te zijn.` : `Number needs to be at least ${minValue}.`
      );
    } else if (maxValue !== undefined && maxValue !== null && number > maxValue) {
      throw new Error(
        language === 'nl' ? `Getal mag maximaal ${maxValue} zijn.` : `Number cannot be more than ${maxValue}.`
      );
    }
    total += number;
  }
  if (totalValue !== undefined && total !== totalValue) {
    throw new Error(
      language === 'nl'
        ? `Je hebt nu ${total} ingevuld. Totaal van waardes moet exact ${totalValue} zijn`
        : `You now have filled in ${total}. Total of values should be exactly ${totalValue}`
    );
  }

  return answer;
};
