import React, { useEffect, useRef, useState } from 'react';

import validate from '../validators/validateNumber';

const QuestionFormNumber = ({ question, savedAnswer, onChange, onSubmit, onSubmitAndBack, children }) => {
  const [text, setText] = useState(savedAnswer === undefined ? '' : savedAnswer);
  const inputRef = useRef(null);
  const { allowDecimals, minValue, maxValue, unitPrefix, unitPostfix } = question;

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChange = event => {
    setText(event.target.value);
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (event.nativeEvent.submitter.id === 'next') {
      const didSucceed = onSubmit(text, validate);
      if (!didSucceed) inputRef.current.focus();
    } else {
      onSubmitAndBack(text);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="field">
        <div className={`control${unitPrefix ? ' has-icons-left' : ''}${unitPostfix ? ' has-icons-right' : ''}`}>
          <input
            ref={inputRef}
            type="number"
            value={text}
            pattern={allowDecimals ? '[0-9]*([,.][0-9]*)?' : '[0-9]+'}
            inputMode="numeric"
            step={allowDecimals ? 'any' : 1}
            min={minValue || minValue === 0 ? minValue : 0}
            max={maxValue || maxValue === 0 ? maxValue : undefined}
            className="input is-medium"
            onKeyPress={event => {
              if (event.key.length === 1 && !event.key.match(/[0-9.,-]/)) event.preventDefault();
            }}
            onChange={handleChange}
          />
          {unitPrefix && <span className="icon is-left">{unitPrefix}</span>}
          {unitPostfix && <span className="icon is-right">{unitPostfix}</span>}
        </div>
      </div>
      {children}
    </form>
  );
};

export default QuestionFormNumber;
