// event.preventDefault();
// try {
//   let selectedKeys = options.map(({ key }) => key).filter(key => stateMap[key]);
//   if (lastOptionIsNone && selectedKeys.length === 0) throw new Error('Geen antwoord geselecteerd.');
//   if (lastOptionIsFreeText && stateMap[freeTextOptionKey]) {
//     if (!freeText) throw new Error('Vul een waarde in bij de vrije optie, of vink deze uit.');
//     selectedKeys = selectedKeys.filter(key => key !== freeTextOptionKey);
//     selectedKeys.push(`${freeTextOptionKey}:${freeText}`);
//   }
//   onAnswer(validateAnswer(question, selectedKeys));
// } catch (err) {
//   setError(err.message);
// }

export default (question, answer, language = 'nl') => {
  const { options, minSelect, maxSelect, lastOptionIsFreeText, lastOptionIsNone } = question;
  if (lastOptionIsNone && answer.length === 0) throw new Error('Geen antwoord geselecteerd.');

  const optionKeys = options.map(option => option.key);
  const noneOptionKey = lastOptionIsNone ? optionKeys[optionKeys.length - 1] : null;
  const freeTextOptionKey = lastOptionIsFreeText ? options[options.length - 1].key : null;
  answer.forEach(chosenOption => {
    const isFreeTextOption = lastOptionIsFreeText && chosenOption.startsWith(`${freeTextOptionKey}:`);
    if (isFreeTextOption && chosenOption === `${freeTextOptionKey}:`) {
      throw new Error(
        language === 'nl'
          ? 'Vul een waarde in bij de vrije optie, of vink deze uit.'
          : 'Please enter a value for the open option, or deselect it.'
      );
    }
    if (!optionKeys.includes(chosenOption) && !isFreeTextOption)
      throw new Error(language === 'nl' ? 'Onbekende antwoord(en) gekozen.' : 'Unknown answer(s) picked.');
    if (chosenOption === noneOptionKey && answer.length > 1)
      throw new Error(language === 'nl' ? 'Ongeldige combinatie.' : 'Invalid combination.');
  });

  if (minSelect && answer.length < minSelect) {
    throw new Error(
      language === 'nl'
        ? `Selecteer minimaal ${minSelect} ${minSelect === 1 ? 'antwoord' : 'antwoorden'}.`
        : `Please select at least ${minSelect} ${minSelect === 1 ? 'answer' : 'answers'}.`
    );
  } else if (maxSelect && answer.length > maxSelect) {
    throw new Error(
      language === 'nl'
        ? `Selecteer maximaal ${maxSelect} ${maxSelect === 1 ? 'antwoord' : 'antwoorden'}.`
        : `Please select at most ${maxSelect} ${maxSelect === 1 ? 'answer' : 'answers'}.`
    );
  }

  if (answer.length === 1 && answer[0] === noneOptionKey) return [];

  return answer;
};
