import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons/faCheckSquare';

import validate from '../validators/validateOptionMultiple';

import QuestionOption from './QuestionOption';

const FreeTextInput = styled.input.attrs(() => ({
  className: 'input is-small',
  type: 'text',
}))`
  margin-top: 5px;
  margin-bottom: 8px;
`;

const QuestionFormOptionMultiple = ({
  question,
  savedAnswer,
  variables,
  onChange,
  onSubmit,
  onSubmitAndBack,
  onFocusSubmit,
  children,
}) => {
  const { options, lastOptionIsFreeText, lastOptionIsNone } = question;
  const noneOptionKey = lastOptionIsNone ? options[options.length - 1].key : null;
  const freeTextOptionKey = lastOptionIsFreeText ? options[options.length - 1].key : null;
  const optionMap = options.reduce((map, { key }) => {
    map[key] = false;
    return map;
  }, {});

  let initialFreeText = '';
  let initialSelectedKeys = savedAnswer || [];
  if (lastOptionIsFreeText) {
    initialSelectedKeys = initialSelectedKeys.map(key => {
      const components = key.split(':');
      if (components.length <= 1 || components[0] !== freeTextOptionKey) return key;
      const realKey = components.shift();
      initialFreeText = components.join(':');
      return realKey;
    });
  }
  if (lastOptionIsNone && Array.isArray(savedAnswer) && savedAnswer.length === 0) initialSelectedKeys = [noneOptionKey];
  const initialStateMap = initialSelectedKeys.reduce(
    (map, key) => {
      map[key] = true;
      return map;
    },
    { ...optionMap }
  );

  const [stateMap, setStateMap] = useState(initialStateMap);
  const [freeText, setFreeText] = useState(initialFreeText);

  const freeTextInputRef = useRef(null);

  useEffect(onFocusSubmit, []);

  const handleFixedOptionClick = key => {
    if (key === noneOptionKey) {
      setStateMap(s => ({ ...optionMap, [key]: !s[key] }));
    } else {
      setStateMap(s => ({ ...s, [key]: !s[key], [noneOptionKey]: false }));
    }
    onChange();
  };

  const handleFreeTextOptionClick = key => {
    const wasSelected = stateMap[key];
    handleFixedOptionClick(key);
    if (freeTextInputRef.current) {
      if (!wasSelected) {
        freeTextInputRef.current.focus();
      } else {
        freeTextInputRef.current.blur();
      }
    }
  };

  const handleFreeTextChange = (key, value) => {
    setFreeText(value);
    setStateMap(s => ({ ...s, [key]: true }));
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const selectedKeys = options.map(({ key }) => key).filter(key => stateMap[key]);
    if (lastOptionIsFreeText && stateMap[freeTextOptionKey]) {
      const freeTextKeyIndex = selectedKeys.findIndex(key => key === freeTextOptionKey);
      selectedKeys[freeTextKeyIndex] = `${freeTextOptionKey}:${freeText}`;
    }
    if (event.nativeEvent.submitter.id === 'next') {
      onSubmit(selectedKeys, validate);
    } else {
      onSubmitAndBack(selectedKeys);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {options.map(option => (
        <QuestionOption
          key={option.key}
          option={option}
          variables={variables}
          active={stateMap[option.key]}
          icon={stateMap[option.key] ? faCheckSquare : faSquare}
          onClick={option.key === freeTextOptionKey ? handleFreeTextOptionClick : handleFixedOptionClick}
          {...(option.key === noneOptionKey ? { style: { marginTop: 30 } } : {})}
        >
          {option.key === freeTextOptionKey && (
            <FreeTextInput
              ref={freeTextInputRef}
              onChange={event => handleFreeTextChange(option.key, event.target.value)}
              onMouseDown={event => {
                event.stopPropagation();
              }}
              onClick={event => {
                event.stopPropagation();
              }}
              onKeyUp={event => {
                event.preventDefault();
              }}
              value={freeText}
            />
          )}
        </QuestionOption>
      ))}
      {children}
    </form>
  );
};

export default QuestionFormOptionMultiple;
