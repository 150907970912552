import { isEmail, isMobilePhone } from 'validator';

export default (question, answer, language = 'nl') => {
  const { allowEmpty, validator } = question;
  if (!allowEmpty && answer.length === 0) {
    throw new Error(language === 'nl' ? 'Dit veld mag niet worden leeggelaten.' : 'This field cannot be empty.');
  }
  if (validator === 'EMAIL' && !isEmail(answer))
    throw new Error(language === 'nl' ? 'Vul een geldig e-mailadres in.' : 'Please enter a valid email address.');
  if (validator === 'PHONE' && !isMobilePhone(answer))
    throw new Error(language === 'nl' ? 'Vul een geldig telefoonnummer in.' : 'Please enter a valid phone number.');
  return answer;
};
