import { useEffect, useRef, useState } from 'react';

export default value => {
  const [delta, setDelta] = useState(0);
  const previousValueRef = useRef();
  useEffect(() => {
    if (previousValueRef.current !== undefined && previousValueRef.current !== value) {
      setDelta(value - previousValueRef.current);
    }
    previousValueRef.current = value;
  }, [value]);
  return delta;
};
