import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Color from 'color';

import Transition from './Transition';
import DoubleProgressBar from './DoubleProgressBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  justify-content: space-between;
`;

const Questionnaire = ({
  children,
  numberOfQuestions,
  currentQuestionNumber,
  firstUnansweredQuestionNumber,
  title,
  colorPrimary = '#00d1b2',
  colorSecondary = '#ff3860',
}) => {
  const colorPrimaryFaded = Color(colorPrimary).alpha(0.25).string();
  const colorSecondaryFaded = Color(colorSecondary).alpha(0.25).string();
  const colorPrimaryDarkened = Color(colorPrimary).darken(0.06).hex();
  const colorSecondaryDarkened = Color(colorSecondary).darken(0.06).hex();
  const colorPrimaryDarkenedMore = Color(colorPrimary).darken(0.12).hex();
  const colorSecondaryDarkenedMore = Color(colorSecondary).darken(0.12).hex();

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
        <style>{`
          .brand-primary {
            background-color: ${colorPrimary} !important;
          }
          .brand-secondary {
            background-color: ${colorSecondary} !important;
          }
          .brand-primary-faded {
            background-color: ${colorPrimaryFaded} !important;
          }
          .brand-secondary-faded {
            background-color: ${colorSecondaryFaded} !important;
          }
          .button.brand-primary:focus:not(:active), .button.brand-primary.is-focused:not(:active) {
            box-shadow: 0 0 0 0.125em ${colorPrimaryFaded};
          }
          .button.brand-secondary:focus:not(:active), .button.brand-primary.is-focused:not(:active) {
            box-shadow: 0 0 0 0.125em ${colorSecondaryFaded};
          }
          .button.brand-primary:active, .button.is-primary.is-active {
            background-color: ${colorPrimaryDarkenedMore} !important;
          }
          .button.brand-secondary:active, .button.is-primary.is-active {
            background-color: ${colorSecondaryDarkenedMore} !important;
          }
          .button.is-primary:hover, .button.is-primary.is-hovered {
            background-color: ${colorPrimaryDarkened} !important;
          }
          .button.is-secondary:hover, .button.is-primary.is-hovered {
            background-color: ${colorSecondaryDarkened} !important;
          }
        `}</style>
      </Helmet>

      <div className="container" style={{ width: '100%', flexGrow: '0' }}>
        <DoubleProgressBar
          min={1}
          max={numberOfQuestions || 2}
          value1={currentQuestionNumber || 1}
          value2={firstUnansweredQuestionNumber || 1}
        />
      </div>
      <Transition number={currentQuestionNumber} style={{ flexGrow: 1 }}>
        {children}
      </Transition>
    </Container>
  );
};

export default Questionnaire;
