import React, { useEffect, useRef, useState } from 'react';

import validate from '../validators/validateText';

const QuestionFormText = ({ question, savedAnswer, onChange, onSubmit, onSubmitAndBack, children }) => {
  const [text, setText] = useState(savedAnswer || question.prefill || '');
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChange = event => {
    setText(event.target.value);
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (event.nativeEvent.submitter.id === 'next') {
      const didSucceed = onSubmit(text, validate);
      if (!didSucceed) inputRef.current.focus();
    } else {
      onSubmitAndBack(text);
    }
  };

  let customInputType;
  if (question.validator === 'EMAIL') customInputType = 'email';
  else if (question.validator === 'PHONE') customInputType = 'tel';

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="field">
        <div className="control">
          <input
            ref={inputRef}
            type={customInputType || 'text'}
            value={text}
            className="input is-medium"
            onChange={handleChange}
            maxLength={(question.maxLength) ? question.maxLength : 524288}
          />
        </div>
      </div>
      {children}
    </form>
  );
};

export default QuestionFormText;
