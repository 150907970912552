import { useEffect, useState } from 'react';

import { firestore } from '../../../shared/firebase';
import {
  getFirstUnansweredQuestionNumber,
  getFirstNonSkipQuestionNumber,
  getPreviousNonSkipQuestionNumber,
} from '../../../shared/logic/assessment';

const endpointsReference = firestore.collection('endpoints');

const fetchAssessment = async (endpointId, assessmentId) => {
  const endpointReference = endpointsReference.doc(endpointId);
  const assessmentInfoReference = endpointReference.collection('assessments_info').doc(assessmentId);
  const assessmentDataReference = endpointReference.collection('assessments_data').doc(assessmentId);
  const [assessmentInfoSnapshot, assessmentDataSnapshot] = await Promise.all([
    assessmentInfoReference.get(),
    assessmentDataReference.get(),
  ]);
  if (!assessmentInfoSnapshot.exists || !assessmentDataSnapshot.exists) throw new Error('Could not find assessment');
  return {
    info: assessmentInfoSnapshot.data(),
    data: assessmentDataSnapshot.data(),
  };
};

export default (endpointId, assessmentId, requestedQuestionNumber) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [result, setResult] = useState();

  useEffect(() => {
    (async () => {
      try {
        setResult(await fetchAssessment(endpointId, assessmentId));
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [endpointId, assessmentId]);

  let assessment;

  if (result) {
    const { info, data } = result;
    console.log(`Retrieved assessment data with uuid ${assessmentId}`, {
      info,
      data,
    });
    assessment = { info, data };

    let currentQuestionNumber = requestedQuestionNumber;

    // Make sure our number is set and within range
    const firstUnansweredQuestionNumber = getFirstUnansweredQuestionNumber(info.questions, data.answers);
    if (currentQuestionNumber === undefined) {
      currentQuestionNumber = firstUnansweredQuestionNumber;
    } else if (
      Number.isNaN(currentQuestionNumber) ||
      !Number.isInteger(currentQuestionNumber) ||
      currentQuestionNumber < 1 ||
      currentQuestionNumber > firstUnansweredQuestionNumber
    ) {
      currentQuestionNumber = firstUnansweredQuestionNumber;
    }

    // Make sure this is not a question to skip
    const firstNonSkipQuestionNumber = getFirstNonSkipQuestionNumber(
      info.questions,
      data.answers,
      currentQuestionNumber
    );
    if (firstNonSkipQuestionNumber > currentQuestionNumber) {
      currentQuestionNumber = firstNonSkipQuestionNumber;
    }

    currentQuestionNumber = Math.min(currentQuestionNumber, info.questions.length);

    assessment = {
      currentQuestionNumber,
      firstUnansweredQuestionNumber,
      info,
      data,
      saveAnswer: value => {
        const { key } = info.questions[currentQuestionNumber - 1];
        data.answers[key] = value;
        if (data.answers.update_scan_question_number !== undefined) {
          data.answers.update_scan_question_number = currentQuestionNumber;
        }
        setResult(result);

        const endpointReference = endpointsReference.doc(endpointId);
        const assessmentDataReference = endpointReference.collection('assessments_data').doc(assessmentId);
        assessmentDataReference.set(data, { merge: true }).catch(() => {});

        const nextQuestionNumber = getFirstNonSkipQuestionNumber(
          info.questions,
          data.answers,
          currentQuestionNumber + 1
        );
        const previousQuestionNumber = getPreviousNonSkipQuestionNumber(
          info.questions,
          data.answers,
          currentQuestionNumber
        );

        return { previousQuestionNumber, nextQuestionNumber };
      },
    };
  }

  return {
    assessment,
    loading,
    error,
  };
};
