import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TemplatedText from './TemplatedText';

const OptionButton = styled.button.attrs(({ active }) => ({
  className: `button is-medium is-fullwidth${active ? ' is-danger brand-secondary' : ''}`,
}))`
  white-space: normal;
  height: auto;
`;

const ContainerSpan = styled.span`
  width: 100%;
  text-align: left;
`;

const IconSpan = styled.span.attrs(() => ({
  className: 'icon',
}))`
  float: left;
`;

const QuestionOption = ({ option: { key, text }, variables, active, icon, onClick, children, ...props }) => {
  const handleClick = event => {
    event.preventDefault();
    onClick(key);
  };

  return (
    <div className="field">
      <OptionButton
        type="button"
        active={active}
        onMouseDown={event => event.preventDefault()}
        onClick={handleClick}
        {...props}
      >
        <ContainerSpan>
          {icon && (
            <IconSpan>
              <FontAwesomeIcon icon={icon} />
            </IconSpan>
          )}
          <TemplatedText source={text} variables={variables} renderers={{ paragraph: 'span' }} />
          {children}
        </ContainerSpan>
      </OptionButton>
    </div>
  );
};

export default QuestionOption;
