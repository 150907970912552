import React, { useEffect, useRef, useState } from 'react';

import validate from '../validators/validateText';

const QuestionFormTextArea = ({ question, savedAnswer, onChange, onSubmit, onSubmitAndBack, children }) => {
  const [text, setText] = useState(savedAnswer || '');
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChange = event => {
    setText(event.target.value);
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (event.nativeEvent.submitter.id === 'next') {
      const didSucceed = onSubmit(text, validate);
      if (!didSucceed) inputRef.current.focus();
    } else {
      onSubmitAndBack(text);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="field">
        <div className="control">
          <textarea ref={inputRef} className="input" style={{ height: 150 }} onChange={handleChange} maxLength={(question.maxLength) ? question.maxLength : 524288}>
            {text}
          </textarea>
        </div>
      </div>
      {children}
    </form>
  );
};

export default QuestionFormTextArea;
