import React, { useCallback } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { firestore, useFunctionCall } from '../../../shared/firebase';
import useAsyncResult from '../../../shared/hooks/useAsyncResult';
import Question from '../../../shared/components/Question';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import { LanguageProvider } from '../../../shared/components/LanguageProvider';
import { urlToEndpointId } from '../../../shared/logic/endpoint';
import { parseQuery } from '../../../shared/logic/utils';

import Questionnaire from './Questionnaire';

const endpointsReference = firestore.collection('endpoints');

const Endpoint = () => {
  const { params } = useRouteMatch();

  const { inviteUuid } = parseQuery(window.location.search);

  const { hostname } = window.location;
  const endpointUrlPath = params.path || '';
  const endpointId = urlToEndpointId(`${hostname}/${endpointUrlPath}`);

  const fetchEndpoint = useCallback(async () => {
    const endpointReference = endpointsReference.doc(endpointId);
    const endpointSnapshot = await endpointReference.get();
    if (!endpointSnapshot.exists) throw new Error('Could not find endpoint');

    const data = endpointSnapshot.data();
    const formSnapshot = await data.formReference.get();
    const formData = await formSnapshot.data();
    if (formData.redirect) {
      return {
        ...data,
        ...{
          redirect: formData.redirect,
        },
      };
    }

    return data;
  }, [endpointId]);

  const { error, result: endpoint } = useAsyncResult(fetchEndpoint);

  const [{ loading, result: assessmentId }, startAssessment] = useFunctionCall('startAssessment');

  if (endpoint && endpoint.redirect) {
    window.location.assign(endpoint.redirect);
    return <></>;
  }
  if (assessmentId) {
    return (
      <>
        <Redirect to={`/${endpointUrlPath}/${assessmentId}/1`} />
        <Redirect push to={`/${endpointUrlPath}/${assessmentId}/2`} />
      </>
    );
  }

  const options = {};
  let content;

  if (endpoint) {
    const onStart = () => {
      const endpointReference = endpointsReference.doc(endpointId);
      startAssessment({
        endpointPath: endpointReference.path,
        formPath: endpoint.formReference.path,
        assessmentId: undefined,
        inviteUuid,
      });
    };

    Object.assign(options, endpoint.options);
    content = <Question data={endpoint.welcome} onAnswer={onStart} isSubmitting={loading} isFirst={true} />;
  } else if (error) {
    content = <ErrorMessage error={'Error retrieving assessment'} />;
  }

  return (
    <LanguageProvider language={endpoint && endpoint.language}>
      <Questionnaire
        title={options.assessment_title}
        colorPrimary={options.assessment_color_primary}
        colorSecondary={options.assessment_color_secondary}
      >
        {content}
      </Questionnaire>
    </LanguageProvider>
  );
};

export default Endpoint;
