import React, { createContext, useContext } from 'react';

const defaultLanguage = 'nl';
const LanguageContext = createContext(defaultLanguage);

export const LanguageProvider = ({ children, language = defaultLanguage }) => {
  return <LanguageContext.Provider value={language || defaultLanguage}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
