import { useCallback, useState } from 'react';

export default func => {
  const [state, setState] = useState({ loading: false, error: null, result: null });
  const wrapper = useCallback(
    async (...args) => {
      try {
        setState({ loading: true, error: null, result: null });
        const result = await func(...args);
        setState({ loading: false, error: null, result });
      } catch (err) {
        setState({ loading: false, error: err, result: null });
      }
    },
    [func]
  );
  return [state, wrapper];
};
