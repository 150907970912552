import React, { useRef, useState } from 'react';

import styled from 'styled-components';

import TemplatedText from './TemplatedText';
import QuestionErrorMessage from './QuestionErrorMessage';
import QuestionSubmitButton from './QuestionSubmitButton';

import QuestionFormIntermezzo from './QuestionFormIntermezzo';
import QuestionFormNumber from './QuestionFormNumber';
import QuestionFormOptionMultiple from './QuestionFormOptionMultiple';
import QuestionFormOptionSingle from './QuestionFormOptionSingle';
import QuestionFormText from './QuestionFormText';
import { useLanguage } from './LanguageProvider';
import QuestionRedirect from './QuestionRedirect';
import QuestionFormNumberMultiple from './QuestionFormNumberMultiple';
import QuestionFormTextArea from './QuestionFormTextArea';
import QuestionFormTextMultiple from './QuestionFormTextMultiple';
import QuestionBackButton from './QuestionBackButton';

function questionFormFromType(type) {
  switch (type) {
    case 'QuestionTypeIntermezzo':
      return QuestionFormIntermezzo;
    case 'QuestionTypeNumber':
      return QuestionFormNumber;
    case 'QuestionTypeNumberMultiple':
      return QuestionFormNumberMultiple;
    case 'QuestionTypeOptionMultiple':
      return QuestionFormOptionMultiple;
    case 'QuestionTypeOptionSingle':
      return QuestionFormOptionSingle;
    case 'QuestionTypeText':
      return QuestionFormText;
    case 'QuestionTypeTextMultiple':
      return QuestionFormTextMultiple;
    case 'QuestionTypeTextArea':
      return QuestionFormTextArea;
    case 'QuestionTypeRedirect':
      return QuestionRedirect;
    default:
      throw new Error(`Unknown question type '${type}'`);
  }
}

const QuestionBox = styled.div.attrs(() => ({
  className: 'box',
}))`
  max-width: 750px;
  padding: 30px;
  margin: 20px;
  margin-top: 0;
`;

const Question = ({
  data,
  variables = {},
  onAnswer = () => {},
  onAnswerAndBack = () => {},
  isSubmitting = false,
  isLast = false,
  isFirst = false,
}) => {
  const [error, setError] = useState(null);
  const submitRef = useRef(null);

  const language = useLanguage();

  const { key, text, description, type } = data;
  const QuestionForm = questionFormFromType(type);

  const onChange = () => {
    setError(null);
  };

  const onSubmit = (answer, validate = (q, a) => a) => {
    if (isLast) return true;
    try {
      onAnswer(validate(data, answer, language));
      return true;
    } catch (err) {
      setError(err);
    }
    return false;
  };

  const onBack = answer => {
    onAnswerAndBack(answer);

    return true;
  };

  const onFocusSubmit = () => {
    if (submitRef.current) submitRef.current.focus();
  };

  return (
    <QuestionBox>
      <div className="content is-medium">
        <TemplatedText source={text} variables={variables} />
      </div>
      {description && (
        <div className="notification">
          <div className="content">
            <TemplatedText source={description} variables={variables} />
          </div>
        </div>
      )}
      <QuestionForm
        question={data}
        savedAnswer={variables[key]}
        variables={variables}
        onChange={onChange}
        onSubmit={onSubmit}
        onSubmitAndBack={onBack}
        onFocusSubmit={onFocusSubmit}
      >
        {!isLast && <QuestionErrorMessage error={error} />}
        {!isLast && <QuestionSubmitButton ref={submitRef} caption={data.button} loading={isSubmitting} />}
        {!isFirst && !isLast && <QuestionBackButton ref={submitRef} loading={isSubmitting} />}
      </QuestionForm>
    </QuestionBox>
  );
};

export default Question;
