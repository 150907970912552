import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const QuestionError = ({ error }) => (
  <ErrorContainer className="help is-danger">{(error && error.message) || '\u00A0'}</ErrorContainer>
);

export default QuestionError;
