import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';

const ErrorMessage = ({ error }) => (
  <div className="level">
    <div className="level-left">
      <div className="level-item has-text-danger">
        <span className="icon">
          <FontAwesomeIcon icon={faExclamationCircle} />
        </span>
        {error}
      </div>
    </div>
  </div>
);

export default ErrorMessage;
