import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import useDelta from '../hooks/useDelta';

const animationDuration = 400;
const animationFunction = 'ease';
const animationOffset = '40px';

const TransitionContainer = styled.div`
  position: relative;

  & {
    .item-enter {
      opacity: 0;
      z-index: 1;
    }
    .item-enter-active {
      opacity: 1;
      transition: opacity ${animationDuration}ms ${animationFunction},
        transform ${animationDuration}ms ${animationFunction};
    }
    .item-exit {
      opacity: 1;
    }
    .item-exit-active {
      opacity: 0;
      transition: opacity ${animationDuration}ms ${animationFunction},
        transform ${animationDuration}ms ${animationFunction};
    }
  }

  &.transition-forward {
    .item-enter {
      transform: translateX(${animationOffset});
    }
    .item-enter-active {
      transform: translateX(0);
    }
    .item-exit {
      transform: translateX(0);
    }
    .item-exit-active {
      transform: translateX(-${animationOffset});
    }
  }

  &.transition-backward {
    .item-enter {
      transform: translateX(-${animationOffset});
    }
    .item-enter-active {
      transform: translateX(0);
    }
    .item-exit {
      transform: translateX(0);
    }
    .item-exit-active {
      transform: translateX(${animationOffset});
    }
  }
`;

const ItemContainer = styled.div`
  background-color: #f8f8f8;
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Transition = ({ number, style, children }) => {
  const delta = useDelta(number);

  let transitionClassName = '';
  if (delta < 0) transitionClassName = 'transition-backward';
  else if (delta > 0) transitionClassName = 'transition-forward';

  return (
    <TransitionContainer className={transitionClassName} style={style}>
      <TransitionGroup>
        <CSSTransition key={number} timeout={animationDuration} classNames="item">
          <ItemContainer>{children}</ItemContainer>
        </CSSTransition>
      </TransitionGroup>
    </TransitionContainer>
  );
};

export default Transition;
