export function shouldSkipQuestion({ condition }, answers) {
  if (!condition) return false;
  const match = condition.match(/^(\S+)\s*(<|<=|>|>=|==|!=|CONTAINS)\s*(\S+)$/);
  if (!match) return false;
  const [, operand1, operator, operand2] = match;
  const value1 = answers[operand1];
  const value2 = operand2;
  if (value1 === undefined || value2 === undefined) return true;
  switch (operator) {
    case '<':
      return !(value1 < value2);
    case '<=':
      return !(value1 <= value2);
    case '>':
      return !(value1 > value2);
    case '>=':
      return !(value1 >= value2);
    case '==':
      return !(value1 == value2); // eslint-disable-line eqeqeq
    case '!=':
      return !(value1 != value2); // eslint-disable-line eqeqeq
    case 'CONTAINS':
      return typeof value1 === 'object' && 'includes' in value1 && value1.includes(value2);
    default:
      return false;
  }
}

export function getPreviousNonSkipQuestionNumber(questions, answers, questionNumber) {
  // Start on previous question
  let questionIndex = questionNumber - 2;
  while (questionIndex < questions.length) {
    const question = questions[questionIndex];
    if (!shouldSkipQuestion(question, answers)) break;
    // Go up till we find a question we should not skip
    questionIndex -= 1;
  }
  // +1 in questionIndex for questionNumber
  return questionIndex + 1;
}

export function getFirstNonSkipQuestionNumber(questions, answers, questionNumber) {
  // if (answers.update_scan_question_number !== undefined) {
  //   return answers.update_scan_question_number + 1;
  // }
  // Start on current question
  let questionIndex = questionNumber - 1;
  while (questionIndex < questions.length) {
    const question = questions[questionIndex];
    if (!shouldSkipQuestion(question, answers)) break;
    // Go down till we find a question we should not skip
    questionIndex += 1;
  }
  // +1 in questionIndex for questionNumber
  return questionIndex + 1;
}

export function getFirstUnansweredQuestionNumber(questions, answers) {
  if (answers.update_scan_question_number !== undefined) {
    return answers.update_scan_question_number + 1;
  }
  let questionNumber = 1;
  for (const question of questions) {
    if (question.key in answers || shouldSkipQuestion(question, answers)) {
      questionNumber += 1;
    } else {
      break;
    }
  }
  return questionNumber;
}
