import { isEmail, isMobilePhone } from 'validator';

export default (question, answer, language = 'nl') => {
  const { options, allowEmpty, validator } = question;
  if (!allowEmpty && answer.length < options.length) {
    throw new Error(language === 'nl' ? 'Deze velden mogen niet worden leeggelaten.' : 'These fields cannot be empty.');
  }

  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(answer)) {
    if (validator === 'EMAIL' && !isEmail(value))
      throw new Error(language === 'nl' ? 'Vul een geldig e-mailadres in.' : 'Please enter a valid email address.');
    if (validator === 'PHONE' && !isMobilePhone(value))
      throw new Error(language === 'nl' ? 'Vul een geldig telefoonnummer in.' : 'Please enter a valid phone number.');
    if (!allowEmpty && value === '')
      throw new Error(
        language === 'nl' ? 'Deze velden mogen niet worden leeggelaten.' : 'These fields cannot be empty.'
      );
  }

  return answer;
};
