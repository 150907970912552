import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 20px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  line-height: 16px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Bar = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transition: width 0.4s ease-out;
  width: ${props => `${props.ratio * 100}%`};
  height: 32px;
  border-radius: 30px;
  overflow: hidden;
  background-size: 100%;
`;

const DoubleProgressBar = ({ min = 0, max = 100, value1 = 0, value2 = 0 }) => {
  const boundValue1 = Math.min(Math.max(value1, min), max);
  const boundValue2 = Math.min(Math.max(value2, boundValue1), max);
  const outerRatio = (boundValue2 - min) / (max - min);
  const innerRatio = (boundValue1 - min) / (boundValue2 - min);

  return (
    <Container>
      <Bar ratio={outerRatio} className="outer brand-primary-faded">
        <Bar ratio={innerRatio} className="inner brand-primary" />
      </Bar>
    </Container>
  );
};

export default DoubleProgressBar;
