import React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { LanguageProvider } from '../../../shared/components/LanguageProvider';
import Question from '../../../shared/components/Question';
import { urlToEndpointId } from '../../../shared/logic/endpoint';

import Questionnaire from './Questionnaire';
import useAssessment from '../hooks/useAssessment';

const Assessment = () => {
  const history = useHistory();
  const { params, url } = useRouteMatch();

  const endpointUrlPath = params.path || '';
  const questionNumber = params.questionNumber === undefined ? undefined : parseInt(params.questionNumber, 10);
  const { assessmentId } = params;

  const { hostname } = window.location;
  const endpointId = urlToEndpointId(`${hostname}/${endpointUrlPath}`);

  const { error, assessment } = useAssessment(endpointId, assessmentId, questionNumber);

  const options = {};
  let content;

  if (assessment) {
    const { info, data, saveAnswer } = assessment;

    if (assessment.currentQuestionNumber !== questionNumber) {
      let redirect = `${assessment.currentQuestionNumber}`;
      if (questionNumber === undefined) redirect = `${url.replace(/\/$/, '')}/${assessment.currentQuestionNumber}`;
      return <Redirect to={redirect} />;
    }

    const question = info.questions[assessment.currentQuestionNumber - 1];
    const onAnswer = answer => {
      const { nextQuestionNumber } = saveAnswer(answer);
      history.push(`${nextQuestionNumber}`);
    };

    const onAnswerAndBack = answer => {
      const { previousQuestionNumber } = saveAnswer(answer);
      history.push(`${previousQuestionNumber}`);
    };

    Object.assign(options, info.options);
    content = (
      <Question
        data={question}
        variables={data.answers}
        onAnswer={onAnswer}
        onAnswerAndBack={onAnswerAndBack}
        isLast={assessment.currentQuestionNumber === info.questions.length}
        isFirst={assessment.currentQuestionNumber <= 2}
      />
    );
  } else if (error) {
    // content = <ErrorMessage error={'Error retrieving assessment'} />;

    // Instead of showing an error, let's just redirect to the parent endpoint.
    return <Redirect push to={`/${endpointUrlPath}`} />;
  }

  return (
    <LanguageProvider language={assessment && assessment.info && assessment.info.language}>
      <Questionnaire
        title={options.assessment_title}
        colorPrimary={options.assessment_color_primary}
        colorSecondary={options.assessment_color_secondary}
        numberOfQuestions={assessment && assessment.info.questions.length}
        currentQuestionNumber={assessment && assessment.currentQuestionNumber}
        firstUnansweredQuestionNumber={assessment && assessment.firstUnansweredQuestionNumber}
      >
        {content}
      </Questionnaire>
    </LanguageProvider>
  );
};

export default Assessment;
