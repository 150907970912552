import React, { useEffect, useState } from 'react';

import validate from '../validators/validateNumberMultiple';
import QuestionNumber from './QuestionNumber';

const QuestionFormNumberMultiple = ({
  question,
  savedAnswer,
  onChange,
  onSubmit,
  onSubmitAndBack,
  onFocusSubmit,
  children,
}) => {
  const { options, allowDecimals, minValue, maxValue, unitPrefix, unitPostfix } = question;
  const initialStateMap = savedAnswer || {};
  const [stateMap, setStateMap] = useState(initialStateMap);
  useEffect(onFocusSubmit, []);

  const handleChange = (key, value) => {
    setStateMap(s => ({ ...s, [key]: value }));
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    const values = options.reduce((total, current) => {
      total[current.key] = stateMap[current.key];
      return total;
    }, {});
    if (event.nativeEvent.submitter.id === 'next') {
      onSubmit(values, validate);
    } else {
      onSubmitAndBack(values);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {options.map(option => (
        <QuestionNumber
          key={option.key}
          option={option}
          answer={stateMap[option.key]}
          minValue={option.minValue || minValue}
          maxValue={option.maxValue || maxValue}
          unitPrefix={option.unitPrefix || unitPrefix}
          unitPostfix={option.unitPostfix || unitPostfix}
          allowDecimals={option.allowDecimals || allowDecimals}
          onChange={event => handleChange(option.key, event.target.value)}
        />
      ))}
      {children}
    </form>
  );
};

export default QuestionFormNumberMultiple;
