import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { useCallback, useEffect, useState } from 'react';

import useAsyncFunction from './hooks/useAsyncFunction';
import useAsyncResult from './hooks/useAsyncResult';

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  appId: process.env.FIREBASE_APP_ID,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
};

const app = firebase.initializeApp(config);
export default app;

export const auth = app.auth();
export const firestore = app.firestore();
export const functions = app.functions('europe-west1');

if (process.env.NODE_ENV === 'development') {
  // Setup local emulators for development.
  functions.useFunctionsEmulator('http://localhost:4000');
  firestore.settings({
    host: 'localhost:4001',
    ssl: false,
  });
} else {
  // Initialize analytics when we're not on development.
  app.analytics();
}

export const useFunctionCall = name => {
  const callable = useCallback(
    arg =>
      functions
        .httpsCallable(name)(arg)
        .then(result => result.data),
    [name]
  );
  return useAsyncFunction(callable);
};

export const useFunctionCallResult = (name, arg) => {
  const callable = useCallback(
    () =>
      functions
        .httpsCallable(name)(arg)
        .then(result => result.data),
    [name, arg]
  );
  return useAsyncResult(callable);
};

// XXX move this to dashboard, not assessment should not start authentication
let authState = {};
const authPromise = new Promise(resolve => {
  auth.onAuthStateChanged(
    user => {
      authState = { user };
      resolve();
    },
    error => {
      authState = { error };
      resolve();
    }
  );
});

// XXX move this to dashboard, not assessment should not start authentication
export const useAuthUser = () => {
  const [state, setState] = useState(authState);
  useEffect(() => {
    let didUnsubscribe = false;
    const unsubscribe = auth.onAuthStateChanged(
      user => {
        if (!didUnsubscribe) setState({ user });
      },
      error => {
        if (!didUnsubscribe) setState({ error });
      }
    );
    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, []);

  if ('user' in state) return state.user;
  if ('error' in state) throw state.error;
  throw authPromise;
};
