import React from 'react';
import Markdown from 'react-markdown/with-html';

const builtinFunctions = {
  formatAsCurrency: value =>
    value.toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
    }),
};

const TemplatedText = ({ source, variables = {}, functions = {}, ...props }) => {
  const allFunctions = { ...builtinFunctions, ...functions };
  let processedSource = source;
  processedSource = processedSource.replace(/{{(\w+)\((\w+)\)}}/g, (match, func, value) =>
    func in allFunctions ? allFunctions[func](value in variables ? variables[value] : value) : '...'
  );
  processedSource = processedSource.replace(/{{(\w+)}}/g, (match, variable) =>
    variable in variables ? variables[variable] : '...'
  );
  return <Markdown source={processedSource} escapeHtml={false} {...props} />;
};

export default TemplatedText;
