import React from 'react';
import styled from 'styled-components';

import TemplatedText from './TemplatedText';

const ContainerSpan = styled.span`
  width: 100%;
  text-align: left;
`;

const QuestionText = ({ option, variables, children, ...props }) => {
  return (
    <div className="field">
      <div className="control">
        <ContainerSpan>
          <TemplatedText source={option.text} variables={variables} renderers={{ paragraph: 'span' }} />
          {children}
        </ContainerSpan>
        <input type="text" value={props.answer} className="input is-medium" maxLength={(option.maxLength) ? option.maxLength : 524288} {...props} />
      </div>
    </div>
  );
};

export default QuestionText;
