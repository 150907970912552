import React from 'react';
import styled from 'styled-components';

const FixedWarningTag = styled.span.attrs(() => ({
  className: 'tag is-rounded is-warning is-medium',
}))`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: default;
  user-select: none;
`;

const EnvironmentTag = () => (process.env.TAG ? <FixedWarningTag>{process.env.TAG}</FixedWarningTag> : <></>);

export default EnvironmentTag;
