import React from 'react';
import styled from 'styled-components';

import TemplatedText from './TemplatedText';

const ContainerSpan = styled.span`
  width: 100%;
  text-align: left;
`;

const QuestionNumber = ({
  option: { text },
  minValue,
  maxValue,
  allowDecimals,
  unitPrefix,
  unitPostfix,
  children,
  ...props
}) => {
  return (
    <div className="field">
      <div className={`control${unitPrefix ? ' has-icons-left' : ''}${unitPostfix ? ' has-icons-right' : ''}`}>
        <ContainerSpan>
          <TemplatedText source={text} renderers={{ paragraph: 'span' }} />
          {children}
        </ContainerSpan>
        <input
          type="number"
          pattern={allowDecimals ? '[0-9]*([.][0-9]*)?' : '[0-9]+'}
          inputMode="numeric"
          value={props.answer}
          step={allowDecimals ? 'any' : 1}
          min={minValue || minValue === 0 ? minValue : 0}
          max={maxValue || maxValue === 0 ? maxValue : undefined}
          className="input is-medium"
          onKeyPress={event => {
            if (event.key.length === 1 && !event.key.match(allowDecimals ? /[\d.-]/ : /\d/)) event.preventDefault();
          }}
          {...props}
        />
        {unitPrefix && (
          <span className="icon is-left" style={{ marginTop: '23px' }}>
            {unitPrefix}
          </span>
        )}
        {unitPostfix && (
          <span className="icon is-right" style={{ marginTop: '23px' }}>
            {unitPostfix}
          </span>
        )}
      </div>
    </div>
  );
};

export default QuestionNumber;
