import React, { forwardRef } from 'react';
import { useLanguage } from './LanguageProvider';

const QuestionBackButton = ({ loading, ...props }, ref) => {
  const language = useLanguage();
  const backText = language === 'nl' ? 'Vorige vraag' : 'Previous question';

  return (
    <button
      type="submit"
      id="previous"
      className={`button is-light is-medium is-fullwidth${loading ? ' is-loading' : ''}`}
      style={{ marginTop: `20px` }}
      ref={ref}
      {...props}
    >
      {backText}
    </button>
  );
};

export default forwardRef(QuestionBackButton);
