import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import validate from '../validators/validateOptionSingle';

import QuestionOption from './QuestionOption';

const FreeTextInput = styled.input.attrs(() => ({
  className: 'input is-small',
  type: 'text',
}))`
  margin-top: 5px;
  margin-bottom: 8px;
`;

const QuestionFormOptionSingle = ({
  question,
  savedAnswer,
  variables,
  onChange,
  onSubmit,
  onSubmitAndBack,
  onFocusSubmit,
  children,
}) => {
  const { options, lastOptionIsFreeText } = question;
  const freeTextOptionKey = lastOptionIsFreeText ? options[options.length - 1].key : null;

  let initialSelectedKey = savedAnswer || null;
  let initialFreeText = '';
  if (lastOptionIsFreeText) {
    const components = initialSelectedKey ? savedAnswer.split(':') : [];
    if (components.length > 1 && components[0] === freeTextOptionKey) {
      initialSelectedKey = components.shift();
      initialFreeText = components.join(':');
    }
  }

  const [selectedKey, setSelectedKey] = useState(initialSelectedKey);
  const [freeText, setFreeText] = useState(initialFreeText);

  const freeTextInputRef = useRef(null);

  useEffect(onFocusSubmit, []);

  const handleFixedOptionClick = key => {
    if (freeTextInputRef.current) freeTextInputRef.current.blur();
    setSelectedKey(key);
    onChange();
  };

  const handleFreeTextOptionClick = key => {
    handleFixedOptionClick(key);
    if (freeTextInputRef.current) freeTextInputRef.current.focus();
  };

  const handleFreeTextChange = (key, value) => {
    setFreeText(value);
    setSelectedKey(key);
    onChange();
  };

  const handleSubmit = event => {
    event.preventDefault();
    let key = selectedKey;
    if (lastOptionIsFreeText && key === freeTextOptionKey) key = `${freeTextOptionKey}:${freeText}`;
    if (event.nativeEvent.submitter.id === 'next') {
      onSubmit(key, validate);
    } else {
      onSubmitAndBack(key);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {options.map(option => (
        <QuestionOption
          key={option.key}
          option={option}
          variables={variables}
          active={option.key === selectedKey}
          onClick={option.key === freeTextOptionKey ? handleFreeTextOptionClick : handleFixedOptionClick}
        >
          {option.key === freeTextOptionKey && (
            <FreeTextInput
              ref={freeTextInputRef}
              onChange={event => handleFreeTextChange(option.key, event.target.value)}
              onKeyUp={event => {
                event.preventDefault();
              }}
              value={freeText}
            />
          )}
        </QuestionOption>
      ))}
      {children}
    </form>
  );
};

export default QuestionFormOptionSingle;
