import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import EnvironmentTag from '../../../shared/components/EnvironmentTag';

import Assessment from './Assessment';
import Endpoint from './Endpoint';

const App = () => (
  <Router>
    <EnvironmentTag />
    <Switch>
      {/* Match on UUID / firestore generated ID */}
      <Route
        exact
        path="/:path*/:assessmentId([A-Za-z0-9]{20}|[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})/:questionNumber(\d+)?"
      >
        <Assessment />
      </Route>
      <Route exact path="/:path*">
        <Endpoint />
      </Route>
    </Switch>
  </Router>
);

export default App;
