export default (question, answer, language = 'nl') => {
  const { options, lastOptionIsFreeText } = question;
  const freeTextOptionKey = lastOptionIsFreeText ? options[options.length - 1].key : null;
  if (!answer) {
    throw new Error(
      language === 'nl' ? 'Kies één van de bovenstaande antwoorden.' : 'Please pick one of the answers above.'
    );
  }
  const isFreeTextOption = lastOptionIsFreeText && answer.startsWith(`${freeTextOptionKey}:`);
  if (isFreeTextOption && answer === `${freeTextOptionKey}:`) {
    throw new Error(
      language === 'nl'
        ? 'Vul een waarde in of kies een andere optie.'
        : 'Please enter a value or pick a different option.'
    );
  }
  const optionKeys = options.map(option => option.key);
  if (!optionKeys.includes(answer) && !isFreeTextOption)
    throw new Error(language === 'nl' ? 'Onbekend antwoord gekozen.' : 'Selected unknown answer.');
  return answer;
};
