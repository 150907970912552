import React, { useEffect } from 'react';

import validate from '../validators/validateIntermezzo';

const QuestionFormIntermezzo = ({ onSubmit, onSubmitAndBack, onFocusSubmit, children }) => {
  useEffect(onFocusSubmit, []);

  const handleSubmit = event => {
    event.preventDefault();
    if (event.nativeEvent.submitter.id === 'next') {
      event.preventDefault();
      onSubmit(true, validate);
    } else {
      onSubmitAndBack(true);
    }
  };

  return <form onSubmit={handleSubmit}>{children}</form>;
};

export default QuestionFormIntermezzo;
