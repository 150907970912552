export default (question, answer, language = 'nl') => {
  const { minValue, maxValue, allowDecimals } = question;
  let number = answer;
  if (typeof answer !== 'number') {
    number = allowDecimals ? parseFloat(answer.replace(',', '.')) : parseInt(answer, 10);
  }
  if (Number.isNaN(number)) {
    throw new Error(language === 'nl' ? 'Vul een geldig getal in.' : 'Please enter a valid number.');
  } else if (minValue !== undefined && minValue !== null && number < minValue) {
    throw new Error(
      language === 'nl' ? `Getal dient minimaal ${minValue} te zijn.` : `Number needs to be at least ${minValue}.`
    );
  } else if (maxValue !== undefined && maxValue !== null && number > maxValue) {
    throw new Error(
      language === 'nl' ? `Getal mag maximaal ${maxValue} zijn.` : `Number cannot be more than ${maxValue}.`
    );
  }
  return number;
};
